import React from 'react';
import { Input, InputNumber } from 'antd';
import classnames from 'classnames';
import { ErrorWarningFillIcon } from '@Velocity-Engineering/ve-icons';

import './input.less';

const { TextArea: ATextarea, Search, Group } = Input;

const AInput = Input;

// ensure focus funtionality
const InputCustom = React.forwardRef((props, ref) => {
  const { className, error, ...restProps } = props;

  return (
    <div className={className}>
      <Input
        ref={ref}
        className={classnames('ui-input', {
          'ui-input__error': error,
        })}
        {...restProps}
      />
      {error && (
        <p className="ui-input-container__error-message">
          <ErrorWarningFillIcon />
          {error}
        </p>
      )}
    </div>
  );
});

const InputPassword = React.forwardRef((props, ref) => {
  const { className, error, ...restProps } = props;
  return (
    <div className={className}>
      <Input.Password
        ref={ref}
        className={classnames('ui-input', {
          'ui-input__error': error,
        })}
        {...restProps}
      />
      {error && (
        <p className="ui-input-container__error-message">
          <ErrorWarningFillIcon />
          {error}
        </p>
      )}
    </div>
  );
});

const TextArea = React.forwardRef((props, ref) => {
  const { className, error, ...restProps } = props;
  return (
    <div className={className}>
      <ATextarea
        ref={ref}
        className={classnames('ui-input', {
          'ui-input__error': error,
        })}
        {...restProps}
      />
      {error && (
        <p className="ui-input-container__error-message">
          <ErrorWarningFillIcon />
          {error}
        </p>
      )}
    </div>
  );
});

const InputNum = React.forwardRef((props, ref) => {
  const { className, error, ...restProps } = props;
  return (
    <div className={className}>
      <InputNumber
        ref={ref}
        className={classnames('ui-input ui-input-number', {
          'ui-input__error': error,
        })}
        {...restProps}
      />
      {error && (
        <p className="ui-input-container__error-message">
          <ErrorWarningFillIcon />
          {error}
        </p>
      )}
    </div>
  );
});

export {
  Search,
  TextArea,
  Group,
  InputPassword,
  AInput,
  InputNum as InputNumber,
};

export default InputCustom;
