import { Checkbox } from 'antd';
import classnames from 'classnames';

import './checkbox.less';

function CheckboxCustom(props) {
  const { className, size, checkboxLabel, ...restProps } = props;

  return (
    <Checkbox
      className={classnames(
        className,
        'ui-checkbox-container',
        'ui-checkbox',
        `ui-checkbox--${size}`,
      )}
      size={size}
      {...restProps}
    >
      {checkboxLabel}
    </Checkbox>
  );
}

export default CheckboxCustom;
