import { Button as AntButton, ButtonProps } from 'antd';
import classnames from 'classnames';
import { connect as connectFormik } from 'formik';
import { isEmpty } from '@/ve-utils/common';
import { FileUploadLineIcon } from '@Velocity-Engineering/ve-icons';

import './button.less';

interface Props extends ButtonProps {
  classname?: 'string';
  size?: ButtonProps['size'] | 'medium';
}

const AButton = (props: Props) => {
  const { size, className, ...restProps } = props;

  if (size === 'medium') {
    return (
      <AntButton className={classnames('medium', className)} {...restProps} />
    );
  }

  return <AntButton {...restProps} size={size} className={className} />;
};

function Button(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton className={classnames('ui-button', className)} {...restProps} />
  );
}

function TextButton(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton
      className={classnames('ui-text-button', className)}
      {...restProps}
    />
  );
}

function ButtonLine(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton
      className={classnames('ui-button', 'ui-line-button', className)}
      {...restProps}
    />
  );
}

function PrimaryButton(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton
      className={classnames('ui-primary-button', 'ui-button', className)}
      {...restProps}
    />
  );
}

function PrimaryLineButton(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton
      className={classnames('ui-primary-line-button', 'ui-button', className)}
      {...restProps}
    />
  );
}

function GreenButton(props: Props) {
  const { className, ...restProps } = props;

  return (
    <AButton
      className={classnames('ui-button', className, 'ui-green-button')}
      {...restProps}
    />
  );
}

function GreenLineButton(props: Props) {
  const { className, ...restProps } = props;
  return (
    <AButton
      className={classnames('ui-button', className, 'ui-green-line-button')}
      {...restProps}
    />
  );
}

function RedButton(props: Props) {
  const { className, ...restProps } = props;

  return (
    <AButton
      className={classnames('ui-button', className, 'ui-red-button')}
      {...restProps}
    />
  );
}

/**
 * Formik Reset button will reset the changed fields to its initial state
 * and will be enabled only when the form is modified
 */
function ResetButton({ Component, className, formik, ...props }) {
  const { dirty, isSubmitting } = formik;

  return (
    <Component
      type="reset"
      className={classnames('ui-reset-button', className)}
      disabled={!dirty || isSubmitting}
      {...props}
    />
  );
}

function UploadButton(props: Props) {
  return (
    <AButton
      {...props}
      className={classnames('ui-button', 'ui-upload-button', props.className)}
    >
      {props.children ? (
        props.children
      ) : (
        <>
          <FileUploadLineIcon />
          UPLOAD
        </>
      )}
    </AButton>
  );
}

/**
 * Formik Submit button will trigger the submit action that has been defined.
 * It will be disabled when the form it contains has errors or is already in the process of submission
 */
function SubmitButton({
  Component,
  disableSubmitOnError,
  className,
  formik,
  ...props
}) {
  const { isSubmitting, errors } = formik;
  const disabled = (disableSubmitOnError && !isEmpty(errors)) || isSubmitting;

  return (
    <Component
      htmlType="submit"
      loading={isSubmitting}
      className={classnames(
        'ui-button-submit',
        className,
        { 'ui-btn-disabled': disabled },
        { 'ui-btn-loading': isSubmitting },
      )}
      disabled={disabled}
      {...props}
    />
  );
}

const FormikResetButton = connectFormik(ResetButton);
const FormikSubmitButton = connectFormik(SubmitButton);

export {
  FormikResetButton,
  FormikSubmitButton,
  GreenButton,
  PrimaryButton,
  GreenLineButton,
  ButtonLine,
  PrimaryLineButton,
  RedButton,
  TextButton,
  UploadButton,
};

export default Button;
