import { isProduction } from './common';
import cookie from 'cookie';

export const productionRules = isProduction()
  ? {
      secure: true,
      sameSite: true,
    }
  : {};

const hostname = window.location.hostname;

/**
 * @type {'localhost'|'stagingvelocity.in'|'velocity.in'}
 */

const getBaseDomain = () => {
  return hostname;
};

export const base_domain = getBaseDomain();

// to check if the webpage is loaded inside an iframe or the regular webpage
export const isLoadedOnIframe = window.self !== window.top;

export const cookies = cookie.parse(document.cookie);

/**
 * @typedef {'web' | 'magic' | 'ghost'} Client
 * @typedef {{'access-token':string,uid:string,client:Client}} JWT
 * @type {JWT}
 */

export const jwt = cookies.jwt && JSON.parse(cookies.jwt);

export const isMagicLink = jwt?.client === 'magic';
