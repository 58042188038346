import { useEffect } from 'react';
import { jwt, logout } from '@/ve-utils/api_call';
import { PARTNER_FROM_BUILD_CONFIG } from '@/utils/config';

type Props = {
  children: JSX.Element;
};

const INACTIVITY_TIMEOUT = 1800000; // 30 minutes

const BrowserInactivityWrapper = ({ children }: Props) => {
  // handle inactivity only for velocity builds and web clients
  const isEligibleForInactivityCheck =
    PARTNER_FROM_BUILD_CONFIG === 'velocity' && jwt?.client === 'web';

  useEffect(() => {
    let inactivityTimer: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        // Logging out the user if session is inactive for 30 mins
        if (jwt && isEligibleForInactivityCheck) {
          alert('You have been inactive for 30 minutes. Logging you out.');
          logout();
        }
      }, INACTIVITY_TIMEOUT); // 30 minutes of inactivity
    };

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);

  return <>{children}</>;
};

export default BrowserInactivityWrapper;
