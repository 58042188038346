import PageNotFound from '@/pages/page_not_found/page_not_found';
import { auth } from '@/wrappers';
import { Navigate, redirect, type RouteObject } from 'react-router-dom';
import { errorElement } from './utils';

const routes: RouteObject = {
  path: '/auth',
  lazy: () => import('@/layouts/auth_layout/auth_layout'),
  loader: async () => {
    const redirect_url = auth();
    if (redirect_url) {
      return redirect(redirect_url);
    }
    return null;
  },
  errorElement: <PageNotFound />,
  children: [
    { index: true, element: <Navigate to="/auth/login" /> },
    {
      path: 'sso',
      lazy: () => import('@/pages/auth/sso_authenticator'),
    },
    {
      path: 'ghost',
      lazy: () =>
        import('@/pages/auth/ghost_authenticator/ghost_authenticator'),
    },
    {
      path: 'magic',
      lazy: () => import('@/pages/auth/magic_link_authenticator'),
    },
    {
      path: 'web',
      lazy: () => import('@/pages/auth/web_link_authenticator'),
      errorElement,
    },
    {
      path: 'changePassword',
      lazy: () => import('@/pages/auth/forgot_password/change_password'),
    },
    {
      path: 'forgot_password',
      lazy: () => import('@/pages/auth/forgot_password'),
    },
    {
      path: 'login',
      lazy: () => import('@/pages/auth/login'),
    },
    {
      path: 'otp_verify',
      lazy: () => import('@/pages/auth/signup/otp_verify'),
    },
    {
      path: 'phone_verification',
      lazy: () => import('@/pages/auth/signup/phone_verification'),
    },
    {
      path: 'signup',
      lazy: () => import('@/pages/auth/signup/signup'),
    },
    {
      path: 'set_password',
      lazy: () => import('@/pages/auth/login/set_password'),
    },
  ],
};

export default routes;
