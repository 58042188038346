import { env } from '@/utils/config';
import dayjs from 'dayjs';

export function debounce(callback, delay) {
  let timeout;
  const debouncedMethod = function () {
    const args = Array.from(arguments);
    if (timeout) window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      callback.apply(this, args);
    }, delay || 100);
  };

  debouncedMethod.destroy = function () {
    if (timeout) window.clearTimeout(timeout);
  };

  return debouncedMethod;
}

export function isServer() {
  return typeof window === 'undefined';
}

export function isProduction() {
  return env === 'production';
}

export function isStaging() {
  return env === 'staging';
}

export const decimalValue = (amount) => {
  return (Math.abs(amount) % 1).toFixed(2).substr(1);
};

export function makeQueryParams(query, keys) {
  const newQuery = { ...query };

  if (newQuery.pageNumber) {
    const pageNumber = newQuery.pageNumber;
    delete newQuery['pageNumber'];
    newQuery['page[number]'] = pageNumber;
  }

  let queryString = '';

  Object.keys(newQuery).forEach((key) => {
    if (!keys.includes(key) && newQuery[key]) {
      queryString = queryString + `${key}=${newQuery[key]}&`;
    }
  });

  keys.forEach((key) => {
    if (query[key]) {
      queryString = queryString + parameterizeArray(key, query[key]);
    }
  });

  return queryString.slice(0, queryString.length - 1);
}

const parameterizeArray = (key, arr) => {
  let resultQuery = '';
  arr.split(',').forEach((value) => {
    resultQuery = resultQuery + `${key}[]=${encodeURIComponent(value)}&`;
  });

  return resultQuery;
};

export function getAvatarName(text = '') {
  const splitText = (text || '').trim().toUpperCase().split(' ');

  if (splitText.length > 1) {
    return `${splitText[0][0]}${splitText[1][0]}`;
  }

  return `${splitText[0][0]}${splitText[0][1]}`;
}

export function capitalize(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Temp fix for immer
export function convertToJS(state) {
  return JSON.parse(JSON.stringify(state));
}

export function pluralize(textToPluralize, number) {
  let str = `${textToPluralize}`;
  number = Number(number);

  if (isNaN(number)) return textToPluralize;

  if (number !== 1.0 || number !== 1) {
    str = `${str}s`;
  }
  return str;
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}

export function getAssetURL(path) {
  return `https://assets.velocity.in/${path}`;
}

export function union_arrays(x, y) {
  /* eslint-disable */
  var obj = {};
  for (var i = x.length - 1; i >= 0; --i) obj[x[i]] = x[i];
  for (var i = y.length - 1; i >= 0; --i) obj[y[i]] = y[i];
  var res = [];
  for (var k in obj) {
    if (obj.hasOwnProperty(k))
      // <-- optional
      res.push(obj[k]);
  }
  return res;
}

export const nameCapitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase(),
  );

export const handleSpecialCharsKeyDown = (e, exceptionList) => {
  // reference: https://www.c-sharpcorner.com/blogs/restrict-space-and-special-character-using-javascript1
  // getting the keycode
  const k = e.keyCode || e.which;
  const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')'];

  /* Allowing only the following ascii characters
    8      backspace
    16     shift
    32     space
    65-90  a-z lowercase letters
    97-122 A-Z uppercase letters
  */

  // preventing anything which is not alphabets or numeric.
  let validKey =
    k === 8 ||
    k === 16 ||
    (k > 64 && k < 91) ||
    (k > 96 && k < 123) ||
    (k >= 48 && k <= 57) ||
    e.key === '0';

  // Exclude verification on validKey
  validKey = exceptionList.indexOf(e.key) !== -1 ? true : validKey;

  if (specialChars.includes(e.key)) validKey = false;

  if (!validKey) e.preventDefault();
};

// wont allow copy and paste inside input box
export const preventCopyPaste = (e) => {
  e.preventDefault();
  return false;
};

export const sleep = async (timeout) => {
  return await new Promise((resolve) => window.setTimeout(resolve, timeout));
};

export const makeFileNameEllipsis = (word, len) => {
  if (word?.length > len) {
    return `${word.substr(0, len)}...`;
  }
  return word;
};

export const getSplitName = (name) => {
  const nameArray = name.split(' ');
  return {
    first_name: nameArray[0],
    middle_name: nameArray.length > 2 ? nameArray[nameArray.length - 2] : '',
    last_name: nameArray.length > 1 ? nameArray[nameArray.length - 1] : '',
  };
};

/**
 *
 * Can not select days before today and today
 */
export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day');
};

/**
 * genrate array with n numbers eg: if n is 5 , you get [0,1,2,3,4]
 *  @param {number} n
 */
export const generateArray = (n) => Array.from(Array(n).keys());

export const allowNumericInputOnly = (e) => {
  const keyCode = e.keyCode || e.which;

  // Allow only numeric keys and some control keys
  const isValidKey =
    (keyCode >= 48 && keyCode <= 57) || // Numeric keys
    (keyCode >= 96 && keyCode <= 105) || // Numpad keys
    [8, 9, 13, 37, 39, 46].includes(keyCode); // Backspace, Tab, Enter, Left, Right, Delete

  // Prevent spaces
  if (e.key === ' ' || e.code === 'Space') {
    e.preventDefault();
  }

  if (!isValidKey) {
    e.preventDefault();
  }
};

export const getUniqueValues = ({
  jsonArray,
  key,
}: {
  jsonArray: any[];
  key: string;
}) => {
  return [...new Set(jsonArray.map((item) => item[key]))];
};
