import type { RouteObject } from 'react-router-dom';

const routes: RouteObject = {
  lazy: () => import('@/pages/payments/layout'),
  path: 'payments_pdc',
  children: [
    {
      path: 'payments-requests',
      lazy: () =>
        import(
          '@/pages/payments/pages/digital_pdc/payment_requests/payment_requests'
        ),
    },
    {
      path: 'history',
      lazy: () =>
        import(
          '@/pages/payments/pages/digital_pdc/payment_requests/payment_request_history'
        ),
    },
    {
      path: 'customers',
      lazy: () =>
        import('@/pages/payments/pages/digital_pdc/payment_requests/customers'),
    },
  ],
};

export default routes;
