import { Field as FormikField, FastField } from 'formik';
import * as React from 'react';

export const Field = ({ fast, children, ...restProps }) => {
  if (fast) {
    return <FastField {...restProps}>{children}</FastField>;
  }

  return <FormikField {...restProps}>{children}</FormikField>;
};

export default Field;
