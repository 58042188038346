import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga';

import NotificationHandler from './wrappers/notification';
import BrowserInactivity from './wrappers/browser_inactivity_wrapper';

import { useSetDeviceHeader } from './hooks/use_set_device_header';

import routes from '@/routes';

import './index.css';
import './global.less';
import { isProduction } from './ve-utils/common';

const router = createBrowserRouter(routes);

const App = () => {
  useSetDeviceHeader();
  const isProd = isProduction();
  if (isProd) {
    ReactGA?.initialize('UA-177433365-1');
  }
  return (
    <NotificationHandler>
      <BrowserInactivity>
        <RouterProvider router={router} />
      </BrowserInactivity>
    </NotificationHandler>
  );
};

export default App;
