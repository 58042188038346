import { notification } from 'antd';
import classnames from 'classnames';

import './notification.less';

function errorNotification(config = {}) {
  const { className, ...restConfig } = config;
  notification.open({
    className: classnames('ui-notification__error', className),
    closeIcon: <></>,
    ...restConfig,
  });
}

function successNotification(config = {}) {
  const { className, ...restConfig } = config;
  notification.open({
    className: classnames('ui-notification__success', className),
    closeIcon: <></>,
    ...restConfig,
  });
}

export { errorNotification, successNotification };
