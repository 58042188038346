import { AutoComplete } from 'antd';
import classnames from 'classnames';

import { ErrorWarningFillIcon } from '@Velocity-Engineering/ve-icons';

import Input from '../input/input';

import './autocomplete.less';

function AutoCompleteCustom(props) {
  const {
    className,
    size,
    prefix,
    placeholder,
    CustomInput = Input,
    inputProps = {},
    error,
    value,
    ...restProps
  } = props;

  return (
    <>
      <AutoComplete
        className={classnames(
          className,
          'ui-auto-complete-container',
          'ui-auto-complete',
          `ui-auto-complete-${size}`,
        )}
        value={value}
        {...restProps}
      >
        <CustomInput
          value={value}
          prefix={prefix}
          placeholder={placeholder}
          size={size}
          {...inputProps}
        />
      </AutoComplete>
      {error && (
        <p className="error-message">
          <ErrorWarningFillIcon />
          {error}
        </p>
      )}
    </>
  );
}

export default AutoCompleteCustom;
