import { Select } from 'antd';
import classnames from 'classnames';

import './select.less';

const { Option } = Select;

function SelectCustom(props) {
  const { className, size, prefix, popupClassName, ...restProps } = props;

  if (prefix) {
    return (
      <div
        className={classnames(
          'ui-select-container',
          'ui-select-container__prefix',
          `ui-select--${size}`,
        )}
      >
        <div className="ui-select-container--prefix">{prefix}</div>
        <Select
          className={classnames(className, 'ui-select', `ui-select--${size}`)}
          size={size}
          {...restProps}
        />
      </div>
    );
  }

  return (
    <Select
      className={classnames(
        className,
        'ui-select-container',
        'ui-select',
        `ui-select--${size}`,
      )}
      popupClassName={classnames('ui-select__dropdown-class', popupClassName)}
      size={size}
      {...restProps}
    />
  );
}

export { Option };
export default SelectCustom;
