import type { RouteObject } from 'react-router-dom';

const routes: RouteObject = {
  path: '/buyer-payment/:payment_link/:isUpiEnabled',
  lazy: () =>
    import('@/pages/payments/pages/buyer_payments/buyer_payments_layout'),
  children: [
    {
      index: true,
      lazy: () =>
        import('@/pages/payments/pages/buyer_payments/buyer_payments'),
    },
    {
      path: 'select-method',
      lazy: () =>
        import(
          '@/pages/payments/pages/buyer_payments/components/select_method'
        ),
      children: [
        {
          index: true,
          lazy: () =>
            import(
              '@/pages/payments/pages/buyer_payments/components/method_list'
            ),
        },
        {
          path: ':method',
          lazy: () =>
            import(
              '@/pages/payments/pages/buyer_payments/components/pay_method'
            ),
        },
      ],
    },
    {
      path: 'success',
      lazy: () =>
        import('@/pages/payments/pages/buyer_payments/components/pay_success'),
    },
    {
      path: 'initiated',
      lazy: () =>
        import(
          '@/pages/payments/pages/buyer_payments/components/payment_link_initiated'
        ),
    },
  ],
};

export default routes;
